@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;
}

.email {
  margin-top: 37px;

  @media (--viewportMedium) {
    margin-top: 38px;
  }
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

/* Helper links */
.modalHelperLink {
  @apply --marketplaceModalHelperLink;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  @apply --marketplaceModalHelperText;
}
