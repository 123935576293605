.fieldRoot {
}

.pickerSuccess {
  & :global(.DateInput_input) {
    border-bottom-color: var(--successColor);
  }
}

.pickerError {
  & :global(.DateInput_input) {
    border-bottom-color: var(--failColor);
  }
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.mobileMargins {
  width: calc(100% - 48px);
  margin: 0 24px;

  @media (--viewportMedium) {
    width: 100%;
    margin: 0;
  }
}
